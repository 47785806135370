<template>
  <div class="pad-view">
    <h4>Pad View for Pad Loudness ID: {{ padLoudnessId }}</h4>

    <div v-if="tableData.length">
      <h5>Zone Data</h5>
      <div class="table-responsive mt-3">
        <table class="table table-bordered">
          <tbody>
            <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
              <td>{{ verticalLabels[rowIndex] }}</td>
              <td v-for="(cell, cellIndex) in row" :key="`${rowIndex}-${cellIndex}`" :class="[cell.class, cell.topBorder]">
                {{ cell.value }}
              </td>
            </tr>
            <tr>
              <th></th>
              <th v-for="(value, valueIndex) in horizontalValues" :key="`value-${valueIndex}`">{{ value }}</th>
            </tr>
            <tr>
              <th></th>
              <th v-for="(label, labelIndex) in horizontalLabels" :key="`label-${labelIndex}`">{{ label }}</th>
            </tr>
            <tr>
              <th></th>
              <th v-for="(count, countIndex) in horizontalCounts" :key="`count-${countIndex}`">{{ count }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <p>No zone data available for this pad.</p>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import requests from '@/lib/requests';
import { getToken } from '@/lib/auth';

export default {
  name: 'AudioProfileTable',
  props: {
    padLoudnessId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const verticalLabels = [
      'V16', 'V15', 'V14', 'V13', 'V12', 'V11', 'V10', 'V9', 'V8', 'V7', 'V6', 'V5', 'V4', 'V3', 'V2', 'V1'
    ];

    const horizontalValues = ["125", "250", "500", "625", "750", "1K", "1.25K", "1.4K", "1.5K", "1.7K", "2K", "3K", "4K", "5K", "6K", "8K"];

    const horizontalLabels = [
      'B01', 'B02', 'B03', 'B04', 'B05', 'B06', 'B07', 'B08', 'B09', 'B10', 'B11', 'B12', 'B13', 'B14', 'B15', 'B16'
    ];

    // Initialize the table with all red squares
    const tableData = reactive(Array.from({ length: 16 }, () =>
      Array.from({ length: 16 }, () => ({ value: '', class: 'red', topBorder: '' }))
    ));

    const horizontalCounts = ref([]);
    const errorMessage = ref('');

    // API call to get PAD data
    const fetchPadData = async () => {
      try {
        const response = await requests.get(`/pad/view/${props.padLoudnessId}`, {}, getToken());
        if (response && response.data) {
          const actions = response.data.positions;
          console.log(actions);
          processActions(actions); // Process green and orange zones
        } else {
          errorMessage.value = 'No pad data found.';
        }
      } catch (error) {
        console.error(error);
        errorMessage.value = 'An error occurred while fetching pad data.';
      }
    };

    const processActions = (actions) => {
      actions.forEach(action => {
        if (action.color === 'green') {
          placeSingleBlock(action.x, action.y, 'green');
        } else if (action.color === 'orange') {
          placeOrangeZone(action.x, action.y_start, action.y_end);
        }
      });
      calculateHorizontalCounts();
    };

    // Single block placement
    const placeSingleBlock = (x, y, color) => {
      if (x >= 1 && x <= 16 && y >= 1 && y <= 16) {
        const rowIndex = 16 - y;
        const cellIndex = x - 1;
        tableData[rowIndex][cellIndex] = { value: '', class: color, topBorder: '' };
      }
    };

    // Orange zone placement
    const placeOrangeZone = (x, yStart, yEnd) => {
      if (x >= 1 && x <= 16 && yStart >= 1 && yEnd >= 1 && yStart <= 16 && yEnd <= 16) {
        const startRowIndex = 16 - yStart;
        const endRowIndex = 16 - yEnd;
        const cellIndex = x - 1;

        for (let i = startRowIndex; i >= endRowIndex; i--) {
          if (i === endRowIndex) {
            tableData[i][cellIndex] = { value: '', class: 'orange', topBorder: '' };
          } else {
            tableData[i][cellIndex] = { value: '', class: 'orange', topBorder: 'orange-top-border' };
          }
        }
      }
    };

    const calculateHorizontalCounts = () => {
      horizontalCounts.value = tableData[0].map((_, colIndex) => {
        return tableData.reduce((count, row) => {
          return count + (row[colIndex].class === 'green' ? 1 : 0);
        }, 0);
      });
    };

    onMounted(() => {
      fetchPadData();
    });

    return {
      verticalLabels,
      horizontalValues,
      horizontalLabels,
      horizontalCounts,
      tableData,
      errorMessage
    };
  }
};
</script>

<style scoped>
.table-bordered {
  width: 100%;
  margin: 0;
  padding: 0;
  table-layout: fixed;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  text-align: center;
  vertical-align: middle;
  position: relative;
  width: 6.25%;
}

.green {
  background-color: #28a745;
  color: white;
}

.red {
  background-color: #dc3545;
  color: white;
}

.orange {
  background-color: #ffa500;
  color: white;
}

.orange-top-border {
  border-top: 3px solid #ffa500 !important;
}

.white {
  background-color: white;
}

.bold {
  font-weight: bold;
}
</style>
