<template>
    <div class="container-fluid invite-container">
        <div class="row justify-content-center mx-auto">
            <div class="invite-form position-relative">
                <h2 class="text-center">Invites</h2>

                <!-- Tab-like System for Invite Type -->
                <div class="tab-container mb-3">
                    <div :class="['tab', inviteType === 'phone' ? 'active' : '']" @click="inviteType = 'phone'">
                        Phone
                    </div>
                    <div :class="['tab', inviteType === 'email' ? 'active' : '']" @click="inviteType = 'email'">
                        Email
                    </div>
                </div>

                <form @submit.prevent="sendInvite">
                    <!-- Email Input -->
                    <div v-if="inviteType === 'email'" class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" v-model="email" required />
                    </div>

                    <!-- Phone Input (Country Code + Phone Number) -->
                    <div v-if="inviteType === 'phone'" class="mb-3">
                        <label for="phoneNumber" class="form-label">Phone Number</label>
                        <div class="input-group">
                            <input type="text" class="form-control country-code" id="countryCode" placeholder="+33"
                                v-model="countryCode" required />
                            <input type="text" class="form-control phone-number" id="phoneNumber"
                                placeholder="Phone number" v-model="phoneNumber" required />
                        </div>
                    </div>

                    <!-- First Name and Last Name Input -->
                    <div class="mb-3">
                        <label for="firstName" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="firstName" v-model="firstName" />
                    </div>

                    <div class="mb-3">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="lastName" v-model="lastName" />
                    </div>

                    <!-- Title Input -->
                    <div class="mb-3">
                        <label for="title" class="form-label">Title</label>
                        <input type="text" class="form-control" id="title" v-model="title" />
                    </div>

                    <!-- Age Input -->
                    <div class="mb-3">
                        <label for="age" class="form-label">Age</label>
                        <input type="number" class="form-control" id="age" v-model="age" />
                    </div>

                    <!-- User Type Selection (Only visible to Admin) -->
                    <div v-if="isAdmin" class="mb-3">
                        <label for="userType" class="form-label">User Type</label>
                        <div>
                            <input type="radio" id="normalUser" value="normal" v-model="userType" />
                            <label for="normalUser" class="ps-2">Normal User</label>
                        </div>
                        <div>
                            <input type="radio" id="portalUser" value="portal" v-model="userType" />
                            <label for="portalUser" class="ps-2">Portal User</label>
                        </div>
                    </div>

                    <!-- Device and Group Selection -->
                    <!-- Device brand select (Only for normal users) -->
                    <div v-if="userType !== 'portal'" class="mb-3">
                        <label for="deviceBrand" class="form-label">Device brand</label>
                        <select class="form-control" id="deviceBrand" v-model="selectedBrand" @change="onBrandChange">
                            <option value="" disabled>Please select a brand</option>
                            <option class="select-dark-text" v-for="brand in brands" :key="brand.brand_id"
                                :value="brand.brand_id">
                                {{ brand.name }}
                            </option>
                        </select>
                    </div>

                    <!-- Device model select (Only for normal users) -->
                    <div v-if="userType !== 'portal' && selectedBrandModels.length" class="mb-3">
                        <label for="deviceModel" class="form-label">Model</label>
                        <select class="form-control select-dark-text" id="deviceModel" v-model="selectedModel">
                            <option value="" disabled>Please select a model</option>
                            <option v-for="model in selectedBrandModels" :key="model.device_model_id"
                                :value="model.device_model_id">
                                {{ model.name }}
                            </option>
                        </select>
                    </div>

                    <!-- Group Selection for Portal Users -->
                    <div v-if="isAdmin && userType === 'portal'" class="mb-3">
                        <label for="group" class="form-label">Group</label>
                        <select class="form-control select-dark-text" id="group" v-model="selectedGroup">
                            <option value="" disabled>Please select a group</option>
                            <option v-for="group in filteredGroups" :key="group.group_id" :value="group.group_id">
                                {{ group.name }}
                            </option>
                        </select>
                    </div>

                    <!-- Submit Button -->
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary">Send Invite</button>
                    </div>
                </form>

                <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";

export default {
  data() {
    return {
      brands: [],
      groups: [],
      email: '',
      phoneNumber: '',
      countryCode: '',
      firstName: '',
      lastName: '',
      title: '',
      age: null,
      selectedBrand: '',
      selectedModel: '',
      selectedGroup: '',
      userType: 'normal',
      inviteType: 'phone', // Default to phone
      isAdmin: false,
      errorMessage: ''
    };
  },
  computed: {
    selectedBrandModels() {
      const selectedBrand = this.brands.find(brand => brand.brand_id === this.selectedBrand);
      return selectedBrand ? selectedBrand.models : [];
    },
    filteredGroups() {
      return this.groups.filter(group => group.name !== 'admin' && group.name !== 'portal-user');
    }
  },
  async created() {
    await this.isUserAdmin();
    if (this.isAdmin) {
      this.getGroups();
    }
    this.getBrands();
  },
  methods: {
    async getGroups() {
      const response = await requests.get("permissions/group", {}, getToken());
      this.groups = response.data;
    },
    async isUserAdmin() {
      const response = await requests.get("permissions/user/group", {}, getToken());
      const permissionsGroups = response.data;
      this.isAdmin = permissionsGroups.some(group => group.name === 'admin');
    },
    async getBrands() {
      try {
        const response = await requests.get("device/brands", {}, getToken());
        this.brands = response.data;
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async sendInvite() {
      try {
        let payload = {};

        if (this.inviteType === 'email') {
          payload.email = this.email;
        } else if (this.inviteType === 'phone') {
          payload.phone = this.phoneNumber.startsWith('0') ? this.phoneNumber.slice(1) : this.phoneNumber;
          payload.country_code = this.countryCode.startsWith('+') ? this.countryCode.slice(1) : this.countryCode;
        }

        payload.first_name = this.firstName;
        payload.last_name = this.lastName;
        payload.title = this.title;
        payload.age = this.age;

        if (this.isAdmin && this.userType === 'portal') {
          if (this.selectedGroup) {
            payload.group = this.selectedGroup;
          } else {
            this.errorMessage = 'Please select a group';
            return;
          }
        } else if (this.userType === 'normal') {
          if (this.selectedBrand && this.selectedModel) {
            payload.brand = this.selectedBrand;
            payload.model = this.selectedModel;
          } else {
            this.errorMessage = 'Please select brand and model';
            return;
          }
        }

        if (this.isAdmin) {
          payload.usertype = this.userType;
        }

        const endpoint = this.userType === 'portal' ? '/transactional/invite' : '/user';
        const response = await requests.post(endpoint, payload, getToken());

        if (response && response.status === 201) {
          this.$router.push('/users/dashboard');
        } else if (response && response.data && response.data.detail) {
          this.errorMessage = response.data.detail;
        } else {
          this.errorMessage = 'An unknown error occurred.';
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
          this.errorMessage = error.response.data.detail;
        } else {
          this.errorMessage = 'An error occurred while sending the invite.';
        }
      }
    },
    onBrandChange() {
      this.selectedModel = '';  // Reset the selected model when the brand changes
    }
  }
};
</script>

<style scoped>
.invite-container {
    margin: auto;
    margin-top: 3vh;
}

.invite-form {
    background-color: #d5dadb;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
}

.tab-container {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-bottom: none;
}

.tab.active {
    background-color: white;
    border-bottom: 1px solid white;
}

.select-dark-text {
    color: black !important;
}

.input-group .country-code {
    max-width: 80px;
    margin-right: 10px;
}

.input-group .phone-number {
    flex-grow: 1;
}

.alert {
    margin-top: 10px;
}

.select-dark-text {
    color: black !important;
    background-color: white !important;
}
</style>