<template>
  <div class="container profile-container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-10">
        <div class="profile-card p-4">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <button @click="goBack" class="btn btn-outline-secondary">
              <font-awesome-icon icon="arrow-left" /> Back
            </button>
            <h2 class="text-center flex-grow-1 mb-0">User Profile</h2>
            <div v-if="!isEditing">
              <button @click="enableEditing" class="btn btn-primary">
                <font-awesome-icon icon="edit" /> Edit
              </button>
            </div>
            <div v-else>
              <button @click="saveChanges" class="btn btn-success me-2">
                <font-awesome-icon icon="save" /> Save
              </button>
              <button @click="isEditing = false" class="btn btn-outline-danger">
                <font-awesome-icon icon="times" /> Cancel
              </button>
            </div>
          </div>

          <div class="profile-info" v-if="user">
            <div class="row">
              <!-- Account Information (Left) -->
              <div class="col-md-6">
                <h4><font-awesome-icon icon="user-circle" /> Account Information</h4>
                <table class="table table-borderless mb-4">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <font-awesome-icon icon="id-badge" /> User ID
                      </th>
                      <td>{{ user.user_id }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <font-awesome-icon icon="calendar-alt" /> Created At
                      </th>
                      <td>{{ formattedCreatedAt }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Contact Information -->
              <div class="col-md-6">
                <h4><font-awesome-icon icon="address-book" /> Contact Information</h4>
                <div v-if="!isEditing">
                  <table class="table table-borderless mb-4">
                    <tbody>
                      <tr>
                        <th scope="row">
                          <font-awesome-icon icon="envelope" /> Email
                        </th>
                        <td>{{ user.email }}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <font-awesome-icon icon="phone" /> Phone
                        </th>
                        <td>+{{ user.country_code }} {{ user.phone }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="email" class="form-control" v-model="editableUser.email" />
                  </div>
                  <div class="mb-3">
                    <label for="phone" class="form-label">Phone</label>
                    <div class="input-group">
                      <span class="input-group-text">+</span>
                      <input type="text" class="form-control" v-model="editableUser.country_code" placeholder="33" style="max-width: 80px;" />
                      <input type="text" class="form-control" v-model="editableUser.phone" placeholder="Phone number" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- Personal Information -->
              <div class="col-md-6">
                <h4><font-awesome-icon icon="user" /> Personal Information</h4>
                <div v-if="!isEditing">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <th scope="row">
                          <font-awesome-icon icon="user" /> First Name
                        </th>
                        <td>{{ user.first_name || 'N/A' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <font-awesome-icon icon="user" /> Last Name
                        </th>
                        <td>{{ user.last_name || 'N/A' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <font-awesome-icon icon="user-tag" /> Title
                        </th>
                        <td>{{ user.title || 'N/A' }}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <font-awesome-icon icon="birthday-cake" /> Age
                        </th>
                        <td>{{ user.age || 'N/A' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div class="mb-3">
                    <label for="first_name" class="form-label">First Name</label>
                    <input type="text" class="form-control" v-model="editableUser.first_name" />
                  </div>
                  <div class="mb-3">
                    <label for="last_name" class="form-label">Last Name</label>
                    <input type="text" class="form-control" v-model="editableUser.last_name" />
                  </div>
                  <div class="mb-3">
                    <label for="title" class="form-label">Title</label>
                    <input type="text" class="form-control" v-model="editableUser.title" />
                  </div>
                  <div class="mb-3">
                    <label for="age" class="form-label">Age</label>
                    <input type="number" class="form-control" v-model="editableUser.age" />
                  </div>
                </div>
              </div>

              <!-- Device Information (Right, Non-editable) -->
              <div class="col-md-6">
                <h4><font-awesome-icon icon="cog" /> Device Information</h4>
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <font-awesome-icon icon="laptop" /> Device Model
                      </th>
                      <td>{{ user.device_model || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <font-awesome-icon icon="industry" /> Manufacturer
                      </th>
                      <td>{{ user.device_manufacturer || 'N/A' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Error Message -->
          <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
            {{ errorMessage }}
          </div>
        </div>
        <PadSelector v-if="user" :id="user.user_id" />
      </div>
    </div>

  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getToken } from "@/lib/auth";
import requests from '@/lib/requests';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PadSelector from '@/components/user/PadSelector.vue';  // Import PadSelector component
import { faArrowLeft, faEdit, faSave, faTimes, faUserCircle, faIdBadge, faCalendarAlt, faAddressBook, faEnvelope, faPhone, faUser, faUserTag, faBirthdayCake, faCog, faLaptop, faIndustry } from '@fortawesome/free-solid-svg-icons';

// Adding necessary icons to the library
library.add(faArrowLeft, faEdit, faSave, faTimes, faUserCircle, faIdBadge, faCalendarAlt, faAddressBook, faEnvelope, faPhone, faUser, faUserTag, faBirthdayCake, faCog, faLaptop, faIndustry);

export default {
  name: 'ProfilePage',
  components: {
    FontAwesomeIcon,
    PadSelector  // Register PadSelector component
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const user = ref(null);
    const editableUser = ref({});
    const errorMessage = ref('');
    const isEditing = ref(false);

    const fetchUserData = async (id) => {
      try {
        const response = await requests.get(`/user/${id}`, {}, getToken());
        if (response && response.data) {
          user.value = response.data;
          editableUser.value = { ...response.data };
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        errorMessage.value = error.response && error.response.data.detail
          ? error.response.data.detail
          : 'An error occurred while fetching the user data.';
      }
    };

    const enableEditing = () => {
      isEditing.value = true;
    };

    const saveChanges = async () => {
      try {
        const token = getToken();
        const payload = {
          email: editableUser.value.email,
          phone: editableUser.value.phone,
          country_code: editableUser.value.country_code,
          first_name: editableUser.value.first_name,
          last_name: editableUser.value.last_name,
          title: editableUser.value.title,
          age: editableUser.value.age
        };
        const userId = user.value.user_id;
        await requests.put(`user/${userId}`, payload, token);
        user.value = { ...editableUser.value };
        isEditing.value = false;
        alert("Profile updated successfully!");
      } catch (error) {
        console.error('Error saving changes:', error);
        errorMessage.value = "Failed to update profile. Please try again.";
      }
    };

    const goBack = () => { 
      router.back();
    };

    onMounted(() => {
      const userId = route.params.id;
      fetchUserData(userId);
    });

    const formattedCreatedAt = computed(() => {
      return user.value ? new Date(user.value.created_at).toLocaleString() : '';
    });

    return {
      user,
      editableUser,
      errorMessage,
      formattedCreatedAt,
      goBack,
      enableEditing,
      saveChanges,
      isEditing
    };
  }
};
</script>

<style scoped>
.profile-container {
  margin-top: 3vh;
}

.profile-card {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.profile-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

h2 {
  margin-bottom: 20px;
}

.btn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  border-radius: 5px;
}

.table th {
  width: 150px;
}

.table th, .table td {
  vertical-align: middle;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 15px;
  border-radius: 5px;
}

.input-group {
  margin-bottom: 15px;
}
</style>
