<template>
  <div class="pad-selector white-box">
    <h4>Pad Loudness Data</h4>

    <div v-if="padData.length">
      <!-- Selector for dates -->
      <div class="mb-3">
        <label for="dateSelector" class="form-label">Select a Date:</label>
        <select id="dateSelector" class="form-select" v-model="selectedPadId" @change="onPadSelect">
          <option v-for="(pad, index) in padData" :key="pad.pad_loudness_id" :value="pad.pad_loudness_id">
            {{ (index + 1) }} - {{ pad.data.start_date ? new Date(pad.data.start_date).toLocaleDateString() : 'Pad Loudness not started' }}
          </option>
        </select>
      </div>

      <!-- Display data for the selected date -->
      <div v-if="selectedPad">
        <div v-if="selectedPad.data.integrety">
          <h5>Session Data ({{ new Date(selectedPad.data.start_date).toLocaleString() }})</h5>
          <p><strong>Session Loudness 1:</strong> {{ selectedPad.data.session_loudness_1 }}</p>
          <p><strong>Session Loudness 2:</strong> {{ selectedPad.data.session_loudness_2 }}</p>

          <!-- Display PadUserView if integrity is valid -->
          <PadUserView :padLoudnessId="selectedPad.pad_loudness_id" />

        </div>

        <!-- Display all data even if session data is valid -->
        <div>
          <!-- Display min/max data -->
          <h6>Min/Max Values</h6>
          <table class="table">
            <thead>
              <tr>
                <th>Band</th>
                <th>Min</th>
                <th>Max</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in selectedPad.data.min_max" :key="entry.band">
                <td>{{ entry.band }}</td>
                <td>{{ entry.min }}</td>
                <td>{{ entry.max }}</td>
              </tr>
            </tbody>
          </table>

          <!-- Display dubious zones if any -->
          <div v-if="selectedPad.data.dubious_zones.length">
            <h6>Dubious Zones</h6>
            <table class="table">
              <thead>
                <tr>
                  <th>Band</th>
                  <th>Min</th>
                  <th>Max</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="zone in selectedPad.data.dubious_zones" :key="zone.band">
                  <td>{{ zone.band }}</td>
                  <td>{{ zone.min }}</td>
                  <td>{{ zone.max }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <p>No pad data available for this user.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import requests from '@/lib/requests';
import { getToken } from "@/lib/auth";
import { useRouter } from 'vue-router';
import PadUserView from '@/components/user/PadUserView.vue';  // Import the PadUserView component

export default {
  name: 'PadSelector',
  components: {
    PadUserView
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const padData = ref([]);
    const selectedPadId = ref(null);
    const selectedPad = ref(null);
    const errorMessage = ref('');
    const router = useRouter();

    // Fetch pad data on component mount
    const fetchPadData = async () => {
      try {
        const response = await requests.get(`/pad/list/${props.id}`, {}, getToken());
        if (response && response.data && response.data.pad_loudness_data) {
          padData.value = response.data.pad_loudness_data;
          // Default to the first pad loudness data if available
          if (padData.value.length > 0) {
            selectedPadId.value = padData.value[0].pad_loudness_id;
            selectedPad.value = padData.value[0];
          }
        } else {
          errorMessage.value = 'No pad data found.';
        }
      } catch (error) {
        errorMessage.value = 'An error occurred while fetching pad data.';
        console.error(error);
      }
    };

    const onPadSelect = () => {
      selectedPad.value = padData.value.find(pad => pad.pad_loudness_id === selectedPadId.value);
    };

    // Navigate to the new route with user_id and pad_loudness_id
    const viewPad = (padLoudnessId) => {
      router.push(`/users/profile/${props.id}/pad/${padLoudnessId}`);
    };

    onMounted(() => {
      fetchPadData();
    });

    return {
      padData,
      selectedPadId,
      selectedPad,
      errorMessage,
      onPadSelect,
      viewPad
    };
  }
};
</script>

<style scoped>
.white-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.table th, .table td {
  vertical-align: middle;
}

.text-danger {
  color: #dc3545;
}
</style>
