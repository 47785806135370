<template>
  <div class="user-pad-view">
    <h2>User Pad View</h2>
    
    <!-- Display basic information about the user and the pad -->
    <div class="pad-info mb-4">
      <h4>User ID: {{ userId }}</h4>
      <h4>Pad Loudness ID: {{ padLoudnessId }}</h4>
    </div>

    <!-- Display PadView component with the pad_loudness_id -->
    <PadUserView :pad-loudness-id="padLoudnessId" />

    <!-- Error message in case something goes wrong -->
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import PadUserView from '@/components/user/PadUserView.vue';  // Make sure the path is correct

export default {
  name: 'UserPadView',
  components: {
    PadUserView
  },
  setup() {
    const route = useRoute();
    const userId = ref(String(route.params.id));  // Convert to string
    const padLoudnessId = ref(String(route.params['pad-loudness-id']));  // Convert to string
    const errorMessage = ref('');

    onMounted(() => {
      if (!padLoudnessId.value) {
        errorMessage.value = 'Pad Loudness ID not found!';
      }
    });

    return {
      userId,
      padLoudnessId,
      errorMessage
    };
  }
};
</script>

<style scoped>
.user-pad-view {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.pad-info {
  margin-bottom: 20px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
  border-radius: 4px;
}
</style>
